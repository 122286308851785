/* You can add global styles to this file, and also import other style files */
@import 'https://library-sdb.apps.bancolombia.com/bds/7.12.9/bds.min.css';

html, body {
    background-color: #F2F2F2;
    font-family: Open Sans, Sans-serif, serif !important;
}

.error-select input {
    border-bottom: 2px solid #fa5e5b !important;
}

.separator {
    border: 0.5px solid #E6E7E8 !important;
    margin-bottom: 18px !important;
    margin-top: 18px !important;
}

.secondary-text {
    font-family: Open Sans, Sans-serif, serif !important;
    font-weight: 400;
    font-style: normal !important;
    font-size: 14px !important;
    line-height: 18px !important;
    letter-spacing: -0.3px !important;
    color: #808285 !important;
    margin-bottom: 0;
}

hr {
    height: 1px;
    border: 0;
    background-color: #e6e7e8;
    border-radius: 4px;
    margin-top: 1%;
}

.aling-center{
  text-align: center;
}

/* mostrar el calendario al hacer click */
input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

/* Estilos de angular material */
.mat-input-element {
    font-size: 16px !important;
}

body {
    margin: 0;
    font-family: Open Sans, Sans-serif, serif !important;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container, .cdk-overlay-connected-position-bounding-box {
    z-index: 99999 !important;
}

.btn-little {
    height: 24px;
    width: 17%;
    border-radius: 19px;
    font-size: 15px;
}

.bc-stepper-title {
  font-style: normal;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.6px;
  color: #000000;
}

section.bc-modal-container[typeIcon=success] section.bc-modal-icon-container div{
  background-color: #00c389 !important;
}
section.bc-modal-container[typeIcon=error] section.bc-modal-icon-container div{
  background-color: #ff7f41 !important;
}


@media only screen and (max-width: 578px),(min-width: 578px) and (max-width: 1023px) {
    .bc-menu-submenu {
        height: 32px !important;
        width: 100px !important;
        display: block !important;
    }

    .bc-submenu-title {
        display: none;
    }

    .bc-menu-item-options {
        display: none;
    }

    .bc-menu-drawer-content > div:nth-child(5) {
        display: none;
    }
}

.bc-header-wrapper.bc-fixed {
    position: fixed;
}

.bc-header-wrapper {
    background-color: #fff;
    width: 100%;
    z-index: 999;
    left: 0;
    top: 0;
    border-bottom: 2px solid #dadada;
}

.bc-avatar, .bc-header-wrapper {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1), 0 0 5px 0 rgba(0, 0, 0, .1);
}

/* Estilos tabs */
.bc-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bc-tabs-group {
    padding: 0;
    margin: 40px 0 0;
}

.bc-tab-header {
    margin-bottom: 0 !important;

}

.bc-tab-body {
    padding: 0 !important;
}

.bc-tabs-group .bc-tab-header .bc-tabs .active {
    font-weight: bold;
    background: #fdda24;
    border-bottom: none;
}

.bc-tab {
    background: white;
    box-shadow: 0 4px 15px -11px rgba(0, 0, 0, 0.75);
}
/* Estilos tabs ^ */

/* Estilos menu */
.bc-menu-item:hover {
    background-color: #fdda24;
}

/* Estilos menu */

/*Estilos tabla material*/
.mat-header-cell, .mat-cell {
    font-family: Open Sans, Sans-serif, serif;
    font-style: normal;
    font-size: 14px !important;
}

/*Estilos tabla material*/

/* Estilos scroll */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Estilos scroll */

.bc-container-solid {
    background: #f2f2f2;
}

.bc-invalid-feedback, .mat-error {
    color: #e20201 !important;
    font-weight: 700;
    font-size: 12px;
}

article.bc-alert-fixed {
    top: 75% !important;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2rem;
}

.header-center {
    width: 100%;
    text-align: center;
}

.title-margin-top{
  margin-top: 7%;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 960px;
    }
}
